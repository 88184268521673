<template>
  <div>
    <navbar
      @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer"
    ></navbar>
    <sidenav ref="drawer"></sidenav>
    <v-container fluid class="py-0"
      ><v-row align-content="center" justify="center" class="mx-0">
        <v-col cols="12" class="main_card_col">
          <v-card elevation="3">
            <v-card-title>
              <span class="mb_max_width">New Animal Identifications</span>
              <v-spacer class="mb_hidden"></v-spacer>
              <div
                class="table_search_field mb_max_width mr-2"
                style="width: 100px"
              >
                <v-select
                  outlined
                  dense
                  v-model="color_filter"
                  label="Color"
                  :items="enums.tag_color"
                  single-line
                  hide-details
                  class="assign_select assign_select_v font_400 rounded-lg"
                  height="32"
                  full-width

                  clearable
                >
                  <template v-slot:item="{ item }">
                    <div>
                      <span
                        v-if="
                          item.value != 'UNDEFINED' && item.value != 'WHITE'
                        "
                        :style="`border: 1px; width: 40px; height: 30px; background-color: ${item.value.toLowerCase()}; display: block`"
                      >
                      </span>
                      <span
                        v-else-if="item.value == 'WHITE'"
                        :style="`border: 1px; width: 40px; height: 30px; background-color: #EFEFEF; display: block`"
                      >
                      </span>
                      <span v-else class="ml-2">-</span>
                    </div>
                  </template>

                  <template v-slot:selection="{ item }">
                    <div>
                      <span
                        v-if="
                          item.value != 'UNDEFINED' && item.value != 'WHITE'
                        "
                        :style="`border: 1px; width: 20px; height: 20px; background-color: ${item.value.toLowerCase()}; display: block`"
                      >
                      </span>
                      <span
                        v-else-if="item.value == 'WHITE'"
                        :style="`border: 1px; width: 20px; height: 20px; background-color: #EFEFEF; display: block`"
                      >
                      </span>
                      <span v-else>-</span>
                    </div>
                  </template>
                </v-select>
              </div>

              <v-menu
                v-model="datetime_menu"
                :close-on-content-click="false"
                open-on-hover
                offset-overflow
              >
                <template v-slot:activator="{ on, attrs }">
                  <div style="width: 180px" class="">
                    <v-text-field
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                      class="mr-2 assign_select assign_select_v font_400 rounded-lg"
                      single-line
                      hide-details
                      dense
                      height="32"
                      full-width
                      outlined
                      readonly
                      v-model="date"
                      :label="date ? date : 'Date'"
                    >
                    </v-text-field>
                  </div>
                </template>

                <v-card height="316" width="280">
                  <v-row>
                    <v-col>
                      <v-date-picker
                        v-model="date"
                        flat
                        no-title
                        id="video_dp"
                        event-color="#ff9900"
                        :max="todays_date"
                      ></v-date-picker>
                    </v-col>
                  </v-row>
                </v-card>
              </v-menu>

              <div class="table_search_field mb_max_width">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  class="assign_select assign_select_v font_400 rounded-lg"
                  label="Search Tags"
                  single-line
                  hide-details
                  dense
                  height="32"
                  full-width
                  outlined
                ></v-text-field>
              </div>
            </v-card-title>
            <v-card-text style="min-height: 100%">
              <v-data-table
                data-cy="table_notes"
                :headers="headers_general"
                :items="selected_identifications"
                :items-per-page="40"
                style="width: 100%"
                class="elevation-1 livestock_table"
                hide-default-footer
                item-key="id"
                :loading="loading"
                no-data-text="No Animal Identifications"
                :sort-by.sync="sort_by"
                :sort-desc.sync="sort_desc"
              >
                <template v-slot:top="{}">
                  <div style="width: 100%; display: flex">
                    <div class="oc_table_header">
                      <v-tabs
                        class="table_tabs animal_tabs pt-1"
                        v-model="selected_table"
                      >
                        <v-tab>Pending</v-tab>
                        <v-tab>Denied</v-tab>
                      </v-tabs>
                    </div>
                  </div>
                </template>
                <template v-slot:[`item.body_img`]="{ item }">
                  <v-img
                    height="100px"
                    width="100px"
                    class="ma-1"
                    style="border-radius: 12px"
                    aspect-ratio="1"
                    :src="cropped_url(item, 'body')"
                  >
                    <Zoom_images :img_src="cropped_url(item, 'body')" />
                  </v-img>
                </template>
                <template v-slot:[`item.head_img`]="{ item }">
                  <v-img
                    height="100px"
                    width="100px"
                    class="ma-1"
                    style="border-radius: 12px"
                    aspect-ratio="1"
                    :src="cropped_url(item, 'head')"
                  >
                    <Zoom_images :img_src="cropped_url(item, 'head')" />
                  </v-img>
                </template>
                <template v-slot:[`item.tag_image`]="{ item }">
                  <v-img
                    height="100px"
                    width="100px"
                    class="ma-1"
                    style="border-radius: 12px"
                    aspect-ratio="1"
                    :src="cropped_url(item, 'tag')"
                  >
                    <v-overlay
                      opacity="0"
                      absolute
                      style="
                        justify-content: start;
                        align-items: start;
                        pointer-events: none;
                      "
                    >
                    </v-overlay>
                  </v-img>
                </template>
                <template v-slot:[`item.label`]="{ item }">
                  <v-row>
                    <v-col cols="6" class="pl-0 py-0">
                      <v-text-field
                        outlined
                        :disabled="allowed_operations != 'WRITE'"
                        dense
                        class="mt-7"
                        v-model="item.detected_tag_label"
                        label="Text"
                      >
                      </v-text-field
                    ></v-col>
                    <v-col cols="6" class="pl-0 py-0">
                      <v-select
                        outlined
                        :disabled="allowed_operations != 'WRITE'"
                        dense
                        v-model="item.detected_tag_color"
                        label="Color"
                        :items="enums.tag_color"
                        hide-no-data
                        class="mt-7"
                        hide-details=""
                      >
                        <template v-slot:item="{ item }">
                          <div>
                            <span
                              v-if="
                                item.value != 'UNDEFINED' &&
                                item.value != 'WHITE'
                              "
                              :style="`border: 1px; width: 40px; height: 30px; background-color: ${item.value.toLowerCase()}; display: block`"
                            >
                            </span>
                            <span
                              v-else-if="item.value == 'WHITE'"
                              :style="`border: 1px; width: 40px; height: 30px; background-color: #EFEFEF; display: block`"
                            >
                            </span>
                            <span v-else class="ml-2">-</span>
                          </div>
                        </template>

                        <template v-slot:selection="{ item }">
                          <div>
                            <span
                              v-if="
                                item.value != 'UNDEFINED' &&
                                item.value != 'WHITE'
                              "
                              :style="`border: 1px; width: 20px; height: 20px; background-color: ${item.value.toLowerCase()}; display: block`"
                            >
                            </span>
                            <span
                              v-else-if="item.value == 'WHITE'"
                              :style="`border: 1px; width: 20px; height: 20px; background-color: #EFEFEF; display: block`"
                            >
                            </span>
                            <span v-else>-</span>
                          </div>
                        </template>
                      </v-select></v-col
                    >
                  </v-row>
                </template>
                <template v-slot:[`item.animal_tag_year_code`]="{ item }">
                  <v-select
                    outlined
                    :disabled="allowed_operations != 'WRITE'"
                    dense
                    class="mt-7"
                    v-model="item.animal_tag_year_code"
                    :items="[
                      'P',
                      'N',
                      'M',
                      'L',
                      'K',
                      'J',
                      'H',
                      'G',
                      'F',
                      'E',
                      'D',
                      'C',
                      'B',
                      'A',
                      'Z',
                      'Y',
                      'X',
                      'W',
                    ]"
                    label="Code"
                  ></v-select>
                </template>
                <template v-slot:[`item.color`]="{ item }">
                  <v-select
                    outlined
                    :disabled="allowed_operations != 'WRITE'"
                    dense
                    class="mt-7"
                    v-model="item.color"
                    :items="enums.tag_color"
                    label="Tag Color"
                  ></v-select>
                </template>
                <template v-slot:[`item.sex_classification`]="{ item }">
                  <v-select
                    outlined
                    :disabled="allowed_operations != 'WRITE'"
                    dense
                    class="mt-7"
                    v-model="item.sex_classification"
                    :items="enums.sex_classification"
                    label="Classification"
                    @change="update_sex(item)"
                  ></v-select>
                </template>
                <template v-slot:[`item.media_timestamp`]="{ item }">
                  <span> {{ item.media_timestamp.split('T')[0] }} </span>
                </template>

                <template v-slot:[`item.sex`]="{ item }">
                  <v-select
                    outlined
                    dense
                    class="mt-7"
                    v-model="item.sex"
                    :items="[
                      { value: 'MALE', text: 'Male' },
                      { value: 'FEMALE', text: 'Female' },
                      { value: 'OTHER', text: 'Other' },
                    ]"
                    label="Sex"
                    :disabled="
                      ['COW', 'FREEMARTIN', 'HEIFER', 'BULL', 'STEER'].includes(
                        item.sex_classification
                      ) && allowed_operations != 'WRITE'
                    "
                  ></v-select>
                </template>
                <template v-slot:[`item.approval`]="{ item }">
                  <div>
                    <div style="display: block">
                      <v-btn
                        @click="approve_item(true, item, true)"
                        style="display: inline-block; width: 30px"
                        text-color="white"
                        small
                        class="mr-2"
                        :color="'primary'"
                        :disabled="allowed_operations != 'WRITE'"
                        ><b>Add</b></v-btn
                      >
                      <v-btn
                        @click="approve_item(true, item)"
                        style="display: inline-block"
                        text-color="white"
                        small
                        :color="'primary'"
                        :disabled="allowed_operations != 'WRITE'"
                        ><v-icon>mdi-cow</v-icon></v-btn
                      >
                    </div>

                    <v-btn
                      @click="approve_item(false, item)"
                      class="mt-2"
                      style="display: block; width: 108px"
                      small
                      v-if="!selected_table"
                      :color="'gray'"
                      :disabled="allowed_operations != 'WRITE'"
                      >No Thanks</v-btn
                    >
                  </div>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card></v-col
        ></v-row
      ></v-container
    >
    <approval_window />
  </div>
</template>
  
  <script>
import { validateNotEmpty } from "@/utilities/ValidationRules";
import { eventBus } from "../main.js";
import moment from "moment-timezone";
import Zoom_images from "@/components/zoom_images.vue";

import navbar from "@/components/Navbar.vue";
import sidenav from "@/components/SideNav.vue";

import approval_window from "@/components/approval_window.vue";

import axios from "axios";
import * as hasura_queries from "../graphql_hasura/queries";
import * as hasura_mutations from "../graphql_hasura/mutations";

import { v4 as uuidv4 } from "uuid";

export default {
  components: { Zoom_images, navbar, sidenav, approval_window },
  name: "AnimalIdentification",
  data() {
    return {
      dialog: false,

      date: null,
      todays_date: moment().format("YYYY-MM-DD"),

      selected_identifications: [],
      search: "",
      datetime_menu: false,
      add_index: null,

      selected_date: null,
      loading: true,

      selected_table: 0,
      lambda: null,

      color_filter: null,
      sort_by: undefined,
      sort_desc: false,
    };
  },
  methods: {
    validateNotEmpty,
    cropped_url(item, type) {
      let ymd = moment(item.media_timestamp).format("YYYY/MM/DD")
      let total_date = moment(item.media_timestamp).format(
        "YYYY-MM-DD_HH-mm-ss"
      );
      return `${process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE}${item.customer_id}/animals/images/${ymd}/${item.id}_${total_date}_${type}.jpg`;
    },
    update_sex(item) {
      if (["COW", "FREEMARTIN", "HEIFER"].includes(item.sex_classification)) {
        item.sex = "FEMALE";
      } else if (["BULL", "STEER"].includes(item.sex_classification)) {
        item.sex = "MALE";
      }
    },

    async approve_item(approval, item, quick, confirm, animal) {
      let index = this.selected_identifications.indexOf(item);
      this.add_index = index;
      this.loading = true;
      var animal_tag = {
        color: item.detected_tag_color ? item.detected_tag_color : "UNDEFINED",
        label: item.detected_tag_label,
        year_code: item.year_code,
      };
      if (approval) {
        var create_animal = {};

        create_animal.customer_id = this.$store.getters.getCustomerID;

        create_animal.species = "CATTLE";
        create_animal.sex = item.sex;
        create_animal.classification = item.sex_classification;

        create_animal.current_image_id = item.id;

        create_animal.site_id = this.$store.getters.getSites[0].id;

        try {
          if (quick) {
            if (!confirm) {
              let animal_count = (
                await axios.post(
                  this.$store.getters.getHasuraParams.url,
                  {
                    query: hasura_queries.list_animals_aggregate,
                    variables: {
                      where: {
                        tag_label: { _eq: item.detected_tag_label },
                        tag_color: { _eq: item.detected_tag_color },
                      },
                    },
                  },
                  {
                    headers: this.$store.getters.getHasuraParams.headers,
                  }
                )
              ).data.data.list_animals_aggregate;
              if (animal_count.aggregate.count) {
                eventBus.$emit("appoval_window_open", {
                  item: item,
                  animal: animal_count.nodes[0],
                });
                return false;
              }
            }
            if (!animal) {
              if (!create_animal.sex) {
                create_animal.sex = "OTHER";
              }
              if (!create_animal.classification) {
                create_animal.classification = "UNDEFINED";
              }
              create_animal.id = uuidv4();

              animal_tag.id = uuidv4();
              animal_tag.animal_id = create_animal.id
              animal_tag.customer_id = this.$store.getters.getCustomerID;
              animal_tag.date = moment().format("YYYY-MM-DDTHH:mm:ss");

              create_animal.tag = { data: animal_tag };

              //create_animal.current_tag_id = animal_tag.id;
              let animal_response = await axios.post(
                this.$store.getters.getHasuraParamsWrite.url,
                {
                  query: hasura_mutations.insert_animal_one,
                  variables: {
                    object1: create_animal,
                  },
                },
                {
                  headers: this.$store.getters.getHasuraParamsWrite.headers,
                }
              );
              if (animal_response.errors || animal_response.data.errors) {
                throw new Error(
                  "GraphQL error: " + animal_response.errors[0].message
                );
              }
              animal_tag.animal_id =
                animal_response.data.data.insert_animal_one.id;
            } else {
              animal_tag.animal_id = animal.id;
              let tag_response = await axios.post(
                this.$store.getters.getHasuraParamsWrite.url,
                {
                  query: hasura_mutations.update_tag_by_pk,
                  variables: {
                    pk_columns: { id: animal.tag_id },
                    _set: animal_tag,
                  },
                },
                {
                  headers: this.$store.getters.getHasuraParamsWrite.headers,
                }
              );
              if (tag_response.errors || tag_response.data.errors) {
                throw new Error("GraphQL error: " + tag_response.data.errors);
              }
            }

            let image_response = await axios.post(
              this.$store.getters.getHasuraParamsWrite.url,
              {
                query: hasura_mutations.update_animal_image_by_pk,
                variables: {
                  pk_columns: { id: item.id },
                  _set: {
                    review_status: "APPROVED",
                    review_datetime: moment().format("YYYY-MM-DDTHH:mm:ss"),
                    animal_id: animal_tag.animal_id
                  },
                },
              },
              {
                headers: this.$store.getters.getHasuraParamsWrite.headers,
              }
            );
            if (image_response.errors || image_response.data.errors) {
              throw new Error("GraphQL error ");
            }
            if (this.add_index > -1) {
              this.selected_identifications.splice(this.add_index, 1);
            }

            this.loading = false;
            eventBus.$emit("add_button_alert", {
              msg: "Successfully accepted identification",
              err: false,
            });
          } else {
            if (!create_animal.sex) {
              delete create_animal.sex;
            }
            if (!create_animal.classification) {
              delete create_animal.classification;
            }
            animal_tag.id = uuidv4();
            animal_tag.customer_id = this.$store.getters.getCustomerID;
            animal_tag.date = moment().format("YYYY-MM-DDTHH:mm:ss");

            create_animal.tag = animal_tag;
            // create_animal.tag.id = item.id;
            create_animal.current_image_id = item.id;
            eventBus.$emit("add_animal_open", create_animal);
          }
        } catch (err) {
          console.log(err);
          this.loading = false;
          eventBus.$emit("add_button_alert", {
            msg: "Error creating the animal",
            err: true,
          });
        }
      } else {
        try {
          animal_tag.review_status = "DENIED";
          let image_response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.update_animal_image_by_pk,
              variables: {
                pk_columns: { id: item.id },
                _set: {
                  review_status: "DENIED",
                  review_datetime: moment().format("YYYY-MM-DDTHH:mm:ss"),
                },
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (image_response.errors || image_response.data.errors) {
            throw new Error("GraphQL error: ");
          }
          if (index > -1) {
            this.selected_identifications.splice(index, 1);
          }
          this.loading = false;

          eventBus.$emit("add_button_alert", {
            msg: "Successfully rejected identification",
            err: false,
          });
        } catch (err) {
          this.loading = false;

          eventBus.$emit("add_button_alert", {
            msg: "Error rejecting identification",
            err: true,
          });
        }
      }
    },
    open_field(te) {
      console.log(te);
    },
    close() {
      this.dialog = false;
    },

    tag_image_src(id, date_time, owner, type) {
      return id, date_time, owner, type;
    },
  },

  computed: {
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
    query_params() {
      return {
        search: this.search,
        date: this.selected_date,
        status: this.selected_table ? "DENIED" : "PENDING",
        color: this.color_filter,
        sort: this.sort_by ? { direction: this.sort_desc } : false,
      };
    },
    url_animal_id_status() {
      return "pending";
    },
    timezeone_offset() {
      if (this.$store.getters.getSites) {
        return this.$store.getters.getSites[0];
      }
      return { site_timezone: "UTC" };
    },
    enums() {
      var enums = {};
      enums.tag_color = this.$store.getters.getEnums.tag_color
        ? this.$store.getters.getEnums.tag_color
        : [];
      enums.production_type = this.$store.getters.getEnums.production_type
        ? this.$store.getters.getEnums.production_type
        : [];
      enums.sex_classification = this.$store.getters.getEnums.sex_classification
        ? this.$store.getters.getEnums.sex_classification
        : [];
      enums.animal_purpose = this.$store.getters.getEnums.animal_purpose
        ? this.$store.getters.getEnums.animal_purpose
        : [];
      enums.ownership_status = this.$store.getters.getEnums.ownership_status
        ? this.$store.getters.getEnums.ownership_status
        : [];
      enums.birth_ease = this.$store.getters.getEnums.birth_ease
        ? this.$store.getters.getEnums.birth_ease
        : [];
      enums.nurse_info = this.$store.getters.getEnums.nurse_info
        ? this.$store.getters.getEnums.nurse_info
        : [];
      enums.dam_status = this.$store.getters.getEnums.dam_status
        ? this.$store.getters.getEnums.dam_status
        : [];
      enums.animal_status = this.$store.getters.getEnums.animal_status
        ? this.$store.getters.getEnums.animal_status
        : [];
      return enums;
    },

    headers_general() {
      var headers = [
        {
          text: "Body",
          value: "body_img",
          sortable: false,
          width: "110px",
        },
        {
          text: "Head",
          value: "head_img",
          sortable: false,
          width: "110px",
        },
        {
          text: "Tag",
          value: "tag_image",
          sortable: false,
          width: "110px",
        },
        {
          text: "Date",
          value: "media_timestamp",
          sortable: false,
          width: "60px",
        },
        {
          text: "Tag Details",
          value: "label",
          width: "150px",
          filter: (value) => {
            if (!this.search) return true;
            if (this.search && !this.search.length) return false;
            return value.includes(this.search);
          },
          sort: () => {
            return false;
          },
        },

        {
          text: "Year Code",
          value: "animal_tag_year_code",
          sortable: false,
          width: "110px",
        },

        {
          text: "Classification",
          value: "sex_classification",
          sortable: false,
          width: "180px",
        },
        {
          text: "Sex",
          value: "sex",
          sortable: false,
          width: "140px",
        },
        {
          text: "Approval",
          value: "approval",
          sortable: false,
          width: "126px",
        },
      ];

      return headers;
    },

    allowed_operations() {
      return this.$store.getters.getAllowedOperations;
    },
  },
  created() {
    eventBus.$on("end_loading_animal_ids", () => {
      this.loading = false;
    });
    eventBus.$on("remove_index", () => {
      if (this.add_index > -1) {
        this.selected_identifications.splice(this.add_index, 1);
      }
    });
    eventBus.$on("approve_animal_confirm", (item) => {
      this.approve_item(true, item.item, true, true, item.animal);
    });
    eventBus.$on("animal_identifications_open", () => {
      this.dialog = true;
    });

    if (this.customer_id) {
      this.$store
        .dispatch("DDB_GET_ANIMAL_IDENTIFICATIONS", this.query_params)
        .then(() => {
          this.selected_identifications = [
            ...this.$store.getters.getAnimalIdendifications,
          ];
          this.loading = false;
        });
    }
  },
  watch: {
    sort_by() {
      console.log(this.sort_by);
    },
    query_params: {
      handler: function () {
        if (this.query_params) {
          this.$store
            .dispatch("DDB_GET_ANIMAL_IDENTIFICATIONS", this.query_params)
            .then(() => {
              this.selected_identifications = [
                ...this.$store.getters.getAnimalIdendifications,
              ];
              this.loading = false;
            });
        }
      },
      deep: true,
    },

    customer_id(n, o) {
      if (o || n) {
        this.$store
          .dispatch("DDB_GET_ANIMAL_IDENTIFICATIONS", this.query_params)
          .then(() => {
            this.selected_identifications = [
              ...this.$store.getters.getAnimalIdendifications,
            ];
            this.loading = false;
          });
      }
    },
    date() {
      if (this.date && this.timezeone_offset) {
        this.selected_date = moment.tz(this.date, "YYYY-MM-DD", this.timezeone_offset).utc().format("YYYY-MM-DDTHH")
      } else if (!this.date) {
        this.selected_dateselected_date = moment().format("YYYY-MM-DDTHH");
      }
    },
  },
};
</script>
  <style lang="css">
</style>
  